const newUuid = () => {
    return (
        Number(String(Math.random()).slice(2)) +
        Date.now() +
        Math.round(window.performance.now())
    ).toString(36);
}

const consumption = {
    'uuid': '',
    'isDefault': false,
    'advanced': true,
    'label': '',
    'unit': '',
    'value': 0,
    'valueTimesUnit': 0,
    'kWhPerYear': 0,
    'kWhPerMonth': 0,
    'costPerYear': 0,
    'costPerMonth': 0
};

const product = {
    'uuid': '',
    'bcId': '',
    'trash': false,
    'label': '',
    'level': 0,
    'category': '',
    'amountAuto': '',
    'unit': '',
    'costPerUnit': '',
    'parent': '',
    'amount': '',
    'costPerUnitMin': '',
    'costPerUnitMax': '',
    'defaultMargin': '',
    'minMargin': '',
    'maxMargin': '',
    'costTotal': '',
    'suppliers': [],
    'dealers': [],
    'children': [],
    'position': 999,
    'pvPowerSetter': false,
    'pvWidth': '',
    'pvHeight': '',
    'pvThick': '',
    'pvPower': '',
    'descriptionIfIncluded': '',
    'descriptionIfExcluded': '',
    'required': false,
    'hidden': false,
    'className': '',
    'variable': ''
};

const supplier = {
    'uuid': '',
    'trash': false,
    'company': {
        'uuid': ''
    },
    'costPerUnit': ''
};

const dealer = {
    'uuid': '',
    'trash': false,
    'user': {
        'uuid': '',
        'company': {
            'uuid': ''
        },
    },
    'minMargin': '',
    'maxMargin': '',
};

const history = {
    'uuid': '',
    'trash': false,
    'created': '',
    'value': '',
    'ident': 'ACTION_SALES_LEADER_POINT',
    'user': {
        'uuid': null
    }
};

const contractor = {
    'contractor': {
        'uuid': '',
        'trash': false,
        'create': true,
        'label': '',
        'status': '',
        'comment': '',
    },
    'team': {
        'uuid': ''
    },
}

const toDo = {
    'uuid': '',
    'color': null,
    'taskname': null,
    'taskuuid': null,
    'position': 0,
    'parentuuid': null,
    'title': '',
    'status': 'pending',
    'done': false,
    'date': '',
    'history': [],
    'notify': [],
    'owners': [],
    'link': [],
    'tip': '',
    'trash': false,
    'custom': true,
    'client': false,
}

export default function getProto(ofWhat, defaults) {
    if (!defaults) {
        defaults = {}
    };
    const uuid = newUuid();
    defaults.uuid = uuid;
    defaults.key = defaults.uuid;
    switch (ofWhat) {
        case 'uuid':
            return defaults.uuid;

        case 'consumption':
            return Object.assign({}, consumption, defaults);

        case 'product':
            return Object.assign({}, product, defaults);

        case 'supplier':
            return Object.assign({}, supplier, defaults);

        case 'dealer':
            return Object.assign({}, dealer, defaults);

        case 'history':
            return Object.assign({}, history, defaults);

        case 'contractor':
            defaults = {
                contractor: {
                    ...contractor.contractor,
                    uuid: uuid,
                    key: uuid,
                }
            }
            return Object.assign({}, contractor, defaults);

        case 'checklistItem':
        case 'toDo':
            return Object.assign({}, toDo, defaults);

        default:
            return null;
    }
}