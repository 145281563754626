import './style.scss';

import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '../icon';
import RoutesList from '../../pages';
import classnames from 'classnames/dedupe';

class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.routes = RoutesList.filter((elem) => elem.inSidebar.visible === true);
    }

    renderSubmenus(category, nav, inCategories, returnObject = false) {
        let thereIsActive = false;
        let thereIsAny = false;
        let lastLabel = "";
        let amount = 0;
        const result = nav.map(
            (route) => {

                if (!this.props.hasPermission(route.includeForPermissions).any) {
                    return;
                }
                if (route.excludeForPermissions.length && this.props.hasPermission(route.excludeForPermissions).any) {
                    return;
                }
                if (inCategories && !route.category.includes(category)) {
                    return;
                }

                thereIsAny = true;

                let url = this.props.buildLink(route.url);
                let data = route.inSidebar;
                data.name = this.props.t(data.name);
                data.label = this.props.t(data.label);
                if (data.label == lastLabel) {
                    data.label = null;
                } else {
                    lastLabel = data.label;
                }

                let pathExploded = this.props.location.pathname.split('/');
                let isActive = '/' + pathExploded[3] === route.url;
                let isOpened = false;

                if (route.url.endsWith('/')) {
                    isActive = '/' + pathExploded[3] + '/' === route.url;
                }

                if (isActive) {
                    thereIsActive = true;
                }

                let sub = '';
                if (data.sub) {
                    const subData = this.renderSubmenus(data.sub, nav, inCategories, true);
                    sub = (
                        <ul className="yay-submenu dropdown-triangle">
                            {subData.content}
                        </ul>
                    );

                    if (subData.thereIsActive) {
                        isOpened = true;
                        thereIsActive = true;
                    }
                }

                amount++;

                return (
                    <React.Fragment key={`${url}-${data.name}`}>
                        {
                            data.label &&
                            (
                                <li className="yay-label">{data.label}</li>
                            )
                        }
                        <li
                            className={
                                classnames({
                                    'yay-item-active': isActive,
                                    'yay-submenu-open': isOpened,
                                })
                            }
                        >
                            {
                                data.name &&
                                (
                                    <NavLink
                                        to={data.sub ? '#' : url}
                                        className={data.sub ? 'yay-sub-toggle' : ''}
                                        onClick={() => { this.props.api.dispatchEvent('HIDE_MOBILE_SIDEBAR') }}
                                    >
                                        {
                                            data.icon ?
                                                (
                                                    <>
                                                        <span className="yay-icon">
                                                            <Icon name={data.icon} />
                                                        </span>
                                                        <span>{data.name}</span>
                                                    </>
                                                )
                                                :
                                                (
                                                    data.name
                                                )
                                        }
                                        {
                                            data.sub &&
                                            (
                                                <span className="yay-icon-collapse">
                                                    <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                                                </span>
                                            )
                                        }
                                    </NavLink>
                                )
                            }
                            {sub}
                        </li>
                    </React.Fragment>
                );
            });

        if (returnObject) {
            return {
                content: result,
                thereIsActive,
                thereIsAny,
                amount
            };
        }

        return result;
    }

    render() {
        if (this.props.isMobileView) {
            return (
                <ul className='p-0'>
                    {
                        this.props.categories.map(category => (
                            <Fragment key={`mobile-category-${category.value}`}>
                                <li className="yay-label fs-14 bg-light border my-10 yay-sticky">
                                    <Icon name={category.iconName} size={16} ccc="true" style={{ marginRight: 10, marginTop: -3 }} />
                                    {category.label}
                                </li>
                                {this.renderSubmenus(category.value, this.routes, true, false)}
                            </Fragment>
                        ))
                    }
                </ul>
            )
        } else {
            return (
                <ul>
                    {this.renderSubmenus(this.props.selectedCategory, this.routes, this.props.inCategories, false)}
                </ul>
            );
        }

    }
}

export default MainMenu
